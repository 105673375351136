/* You can add global styles to this file, and also import other style files */

@import "~ng-pick-datetime/assets/style/picker.min.css";
html,
body {
    height: 100%;
}

body {
    margin: 0;
    font-family: Roboto, "Helvetica Neue", sans-serif;
}

a.internalLink {
    background-color: transparent;
    border: none;
    color: orange;
    cursor: pointer;
}

.mat-form-field-required-marker {
    color: red;
}

.customperformerlogo {
    max-height: 100px;
}

.mat-form-field-no-padding .mat-form-field-wrapper {
    margin-bottom: -1.25em !important;
}

@media only screen and (max-width: 500px) {

    .mat-snack-bar-container-panel-class{
        position: absolute;
        bottom: 20% !important;
    }

    .mat-snack-bar-handset .mat-snack-bar-container{
        width: 80% !important;
    }
    
}

.cdk-overlay-pane {  
    &.mat-tooltip-panel {  
     .mat-tooltip { 
        background-color: black;
        } 
    } 
}
